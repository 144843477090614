var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-button",
    {
      directives: [
        {
          name: "b-tooltip",
          rawName: "v-b-tooltip.hover.html.right",
          modifiers: { hover: true, html: true, right: true }
        }
      ],
      attrs: {
        size: "sm",
        variant: "outline",
        title: _vm.title,
        show: _vm.showToolTip
      },
      on: {
        click: function($event) {
          return _vm.onClick(_vm.rel, _vm.mediaType)
        },
        "update:show": function($event) {
          _vm.showToolTip = $event
        }
      }
    },
    [
      _vm.type === "Delete"
        ? _c("Delete", { attrs: { h: _vm.h, w: _vm.w } })
        : _vm.type === "Add"
        ? _c("Add", { attrs: { h: _vm.h, w: _vm.w } })
        : _vm.type === "Edit"
        ? _c("Edit", { attrs: { h: _vm.h, w: _vm.w } })
        : _vm.type === "Search"
        ? _c("Search", { attrs: { h: _vm.h, w: _vm.w } })
        : _vm.type === "Put"
        ? _c("Put", { attrs: { h: _vm.h, w: _vm.w } })
        : _c("Patch", { attrs: { h: _vm.h, w: _vm.w } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }