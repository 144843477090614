var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.loading
    ? _c(
        "span",
        { staticClass: "whole-page" },
        [
          _c("simple-spinner", {
            attrs: {
              "animation-duration": 1000,
              size: 40,
              color: "#ff1d5e",
              message: _vm.message
            }
          }),
          _vm._v(" "),
          _c("b-link", { on: { click: _vm.cancel } }, [_vm._v("Cancel")])
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }