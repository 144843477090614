var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-tabs",
        {
          ref: "view",
          attrs: { id: "view" },
          model: {
            value: _vm.currentViewIndex,
            callback: function($$v) {
              _vm.currentViewIndex = $$v
            },
            expression: "currentViewIndex"
          }
        },
        [
          _c(
            "b-tab",
            { attrs: { title: "JSON" } },
            [
              _c(
                "b-container",
                { staticClass: "m-3 pr-3", attrs: { fluid: "" } },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: !_vm.formRepresentation,
                          expression: "!formRepresentation"
                        }
                      ]
                    },
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.html.bottom",
                              modifiers: {
                                hover: true,
                                html: true,
                                bottom: true
                              }
                            }
                          ],
                          attrs: {
                            size: "sm",
                            title: "Copy the data to the clipboard"
                          },
                          on: { click: _vm.copyToClipboard }
                        },
                        [
                          _vm._v(
                            "\n                        Copy\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.html.bottom",
                              modifiers: {
                                hover: true,
                                html: true,
                                bottom: true
                              }
                            }
                          ],
                          attrs: {
                            size: "sm",
                            title: "Save the data to a file"
                          },
                          on: { click: _vm.saveToFile }
                        },
                        [
                          _vm._v(
                            "\n                        Save\n                    "
                          )
                        ]
                      ),
                      _vm._v(" "),
                      !_vm.prettyprint
                        ? _c(
                            "b-button",
                            {
                              attrs: { size: "sm" },
                              on: {
                                click: function($event) {
                                  _vm.prettyprint = !_vm.prettyprint
                                }
                              }
                            },
                            [_vm._v("Raw")]
                          )
                        : _c(
                            "b-button",
                            {
                              attrs: { size: "sm", variant: "outline" },
                              on: {
                                click: function($event) {
                                  _vm.prettyprint = !_vm.prettyprint
                                }
                              }
                            },
                            [_vm._v("Pretty Print\n                    ")]
                          ),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _vm.prettyprint
                        ? _c("pre", [_vm._v(_vm._s(_vm.representation))])
                        : _c("pre", {
                            domProps: {
                              innerHTML: _vm._s(_vm.htmlRepresentation)
                            }
                          })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.formRepresentation
                    ? _c("Form", {
                        attrs: {
                          representation: _vm.representation,
                          formRepresentation: _vm.formRepresentation,
                          "on-cancel": _vm.onClose
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Headers" } },
            [
              _c("headers", {
                staticClass: "m-3",
                attrs: { title: "Request Headers", headers: _vm.requestHeaders }
              }),
              _vm._v(" "),
              _c("hr"),
              _vm._v(" "),
              _c("headers", {
                staticClass: "m-3",
                attrs: {
                  title: "Response Headers",
                  headers: _vm.responseHeaders
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-tab",
            { attrs: { title: "Logout" } },
            [
              _c(
                "b-container",
                { attrs: { fluid: "" } },
                [
                  _c("Logout", {
                    staticClass: "mt-3",
                    attrs: { "on-logout": _vm.onLogout }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }