var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    {
      ref: "draggable",
      on: {
        drop: _vm.drop,
        dragstart: _vm.dragstart,
        dragover: _vm.dragover,
        dragenter: _vm.dragenter,
        dragleave: _vm.dragleave,
        dragend: _vm.dragend
      }
    },
    [_vm._t("default", [_c("span", [_vm._v("+")])])],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }