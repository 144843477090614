var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form",
    { on: { submit: _vm.submit } },
    [
      _vm._l(_vm.formRepresentation.items, function(item) {
        return _c(
          "b-form-group",
          { key: item.name, attrs: { label: item.name } },
          [
            _vm.mapApiToUiType(item.type) === "date" ||
            _vm.mapApiToUiType(item.type) === "datetime"
              ? [
                  _c("datetime", {
                    attrs: {
                      type: _vm.mapApiToUiType(item.type),
                      "input-class": "form-control",
                      phrases: { ok: "Continue", cancel: "Exit" },
                      "use12-hour": "",
                      auto: "",
                      "min-datetime": _vm.minDatetime
                    },
                    model: {
                      value: _vm.formObj[item.name],
                      callback: function($$v) {
                        _vm.$set(_vm.formObj, item.name, $$v)
                      },
                      expression: "formObj[item.name]"
                    }
                  }),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.localDateTime.zoneName) +
                        " (" +
                        _vm._s(_vm.localDateTime.offsetNameLong) +
                        ")"
                    )
                  ])
                ]
              : _vm.mapApiToUiType(item.type) === "check"
              ? _c("b-form-radio-group", {
                  attrs: {
                    buttons: "",
                    "button-variant": "outline-primary",
                    size: "sm",
                    options: [
                      { text: "True", value: true },
                      { text: "False", value: false }
                    ]
                  },
                  model: {
                    value: _vm.formObj[item.name],
                    callback: function($$v) {
                      _vm.$set(_vm.formObj, item.name, $$v)
                    },
                    expression: "formObj[item.name]"
                  }
                })
              : _vm.mapApiToUiType(item.type) === "select"
              ? _c(
                  "b-form-select",
                  {
                    model: {
                      value: _vm.formObj[item.name],
                      callback: function($$v) {
                        _vm.$set(_vm.formObj, item.name, $$v)
                      },
                      expression: "formObj[item.name]"
                    }
                  },
                  [
                    _c(
                      "option",
                      { attrs: { disabled: "" }, domProps: { value: null } },
                      [_vm._v("-- Please select an option --")]
                    ),
                    _vm._v(" "),
                    _vm._l(item.items, function(option) {
                      return [
                        _c("option", { domProps: { value: option.value } }, [
                          _vm._v(_vm._s(option.label))
                        ])
                      ]
                    })
                  ],
                  2
                )
              : _c("b-form-input", {
                  attrs: {
                    id: "input-1-" + item.name,
                    type: _vm.mapApiToUiType(item.type),
                    required: item.required,
                    placeholder: item.description
                  },
                  model: {
                    value: _vm.formObj[item.name],
                    callback: function($$v) {
                      _vm.$set(_vm.formObj, item.name, $$v)
                    },
                    expression: "formObj[item.name]"
                  }
                }),
            _vm._v(" "),
            _c("span", { staticClass: "highlight" }),
            _c("span", { staticClass: "bar" })
          ],
          2
        )
      }),
      _vm._v(" "),
      !_vm.noCancel
        ? _c(
            "b-button",
            { attrs: { variant: "outline" }, on: { click: _vm.onCancel } },
            [_c("Back", { attrs: { h: "26px", w: "26px" } })],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-button",
        { attrs: { variant: "primary" }, on: { click: _vm.submit } },
        [_vm._v(_vm._s(_vm.submitTitle))]
      )
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }