var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.authenticated
    ? _c(
        "b-form",
        { on: { submit: _vm.submit } },
        [
          _c(
            "b-button",
            { attrs: { variant: "primary" }, on: { click: _vm.submit } },
            [_vm._v("Logout")]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }