var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.headers
    ? _c(
        "div",
        [
          _c("h3", [_vm._v(_vm._s(_vm.title))]),
          _vm._v(" "),
          _c(
            "b-container",
            { attrs: { fluid: "" } },
            _vm._l(_vm.headers, function(value, key, index) {
              return _c(
                "b-row",
                { key: value + "-" + key + "-" + index, staticClass: "row" },
                [
                  _c(
                    "b-col",
                    {
                      staticClass: "text-right header-key",
                      attrs: { cols: "2" }
                    },
                    [_vm._v(_vm._s(key))]
                  ),
                  _vm._v(" "),
                  _c("b-col", { attrs: { cols: "10" } }, [
                    _vm._v(_vm._s(value))
                  ])
                ],
                1
              )
            }),
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }