var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slideup" } }, [
    _vm.offline
      ? _c("div", { staticClass: "offline-indicator" }, [
          _c("h1", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("API not responding")
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("div", [
              _vm._v(
                "It looks like somebody has broken the internet ... please be patient while we fix it"
              )
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v("Last seen: " + _vm._s(_vm._f("timeago")(_vm.lastseen)))
            ]),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "Checking again in: " + _vm._s(_vm._f("timeago")(_vm.waitFor))
              )
            ])
          ])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }