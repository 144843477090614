var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "span",
    [
      _vm.hasLinkRel
        ? _c(
            "drag-and-droppable-model",
            {
              attrs: {
                model: _vm.representation,
                "media-type": _vm.mediaType,
                accept: _vm.accept,
                dropped: _vm.update
              }
            },
            [
              _c("FormAction", {
                attrs: {
                  "media-type": _vm.mediaType,
                  rel: _vm.rel,
                  type: _vm.method,
                  title: _vm.title
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }